import Big from 'big.js'
import clsx, { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Breakpoint, breakpoints } from 'src/data/breakpoints'
import { CLIENT_BASE_URL } from 'src/data/constants'

export type RoundingMode = 'roundDown' | 'roundUp' | 'roundHalfUp' | 'roundHalfEven'

export const convertFromBaseUnits = (amount = 0, decimals = 0, rm: RoundingMode = 'roundDown') => {
  if (decimals === 0) return amount
  if (!amount) return 0
  const divisor = new Big(10).pow(decimals)
  const baseUnits = new Big(amount).round(decimals, Big[rm])
  return baseUnits.div(divisor).toNumber()
}

export const convertToBaseUnits = (amount = 0, decimals = 0, rm: RoundingMode = 'roundDown') => {
  if (decimals === 0) return amount
  if (!amount) return 0
  const multiplier = new Big(10).pow(decimals)
  const wholeUnits = new Big(amount).round(decimals, Big[rm])
  return wholeUnits.times(multiplier).toNumber()
}

/**
 * Converts an amount in microalgos to Algos:
 * ```
 * microalgos / (10 ^ 6)
 * ```
 * @example
 * // returns 420.69
 * convertMicroalgosToAlgos(420690000)
 *
 * @param microalgos
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `microalgos` converted to Algos
 */
export const convertMicroalgosToAlgos = (microalgos = 0, rm: RoundingMode = 'roundDown') => {
  if (!microalgos) return 0
  const divisor = new Big(10).pow(6)
  return new Big(microalgos).div(divisor).round(6, Big[rm]).toNumber()
}

/**
 * Converts an amount in Algos to microalgos:
 * ```
 * algos * (10 ^ 6)
 * ```
 * @example
 * // returns 420690000
 * convertAlgosToMicroalgos(420.69)
 *
 * @param algos
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `algos` converted to microalgos
 */
export const convertAlgosToMicroalgos = (algos = 0, rm: RoundingMode = 'roundDown') => {
  if (!algos) return 0
  const multiplier = new Big(10).pow(6)
  return new Big(algos).times(multiplier).round(0, Big[rm]).toNumber()
}

/**
 * Converts an amount in USD to microalgos:
 * ```
 * (usd / algoUsd) * (10 ^ 6)
 * ```
 * @example
 * // returns 420690000
 * convertUsdToMicroalgos(89.60, 0.213)
 *
 * @param usd
 * @param algoUsd current ALGO/USD price
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `usd` converted to microalgos
 */
export const convertUsdToMicroalgos = (usd = 0, algoUsd = 0, rm: RoundingMode = 'roundDown') => {
  if (!usd || !algoUsd) return 0
  const algos = new Big(usd).div(algoUsd).round(6, Big[rm]).toNumber()
  return convertAlgosToMicroalgos(algos)
}

/**
 * Converts an amount in microalgos to USD:
 * ```
 * (microalgos / (10 ^ 6)) * algoUsd
 * ```
 * @example
 * // returns 89.60
 * convertMicroalgosToUsd(420690000, 0.213)
 *
 * @param microalgos
 * @param algoUsd current ALGO/USD price
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `microalgos` converted to USD
 */
export const convertMicroalgosToUsd = (
  microalgos = 0,
  algoUsd = 0,
  rm: RoundingMode = 'roundDown'
) => {
  if (!microalgos || !algoUsd) return 0
  const algos = convertMicroalgosToAlgos(microalgos)
  return new Big(algos).times(algoUsd).round(2, Big[rm]).toNumber()
}

/**
 * Converts an amount in USD to cents:
 * ```
 * usd * 100
 * ```
 * @example
 * // returns 42069
 * convertUsdToCents(420.69)
 *
 * @param usd
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `usd` converted to cents
 */
export const convertUsdToCents = (usd = 0, rm: RoundingMode = 'roundDown') => {
  if (!usd) return 0
  return new Big(usd).times(100).round(0, Big[rm]).toNumber()
}

/**
 * Converts an amount in cents to USD:
 * ```
 * cents / 100
 * ```
 * @example
 * // returns 420.69
 * convertCentsToUsd(42069)
 *
 * @param cents
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `cents` converted to USD
 */
export const convertCentsToUsd = (cents = 0, rm: RoundingMode = 'roundDown') => {
  if (!cents) return 0
  return new Big(cents).div(100).round(2, Big[rm]).toNumber()
}

/**
 * Converts an amount in cents to microalgos:
 * ```
 * ((cents / 100) / algoUsd) * (10 ^ 6)
 * ```
 * @example
 * // returns 1975070422
 * convertCentsToMicroalgos(42069, 0.213)
 *
 * @param cents
 * @param algoUsd current ALGO/USD price
 * @param rm Big.js rounding mode {@link https://mikemcl.github.io/big.js/#rm}
 * @returns `cents` converted to microalgos
 */
export const convertCentsToMicroalgos = (
  cents = 0,
  algoUsd = 0,
  rm: RoundingMode = 'roundDown'
) => {
  if (!cents || !algoUsd) return 0
  const usd = new Big(cents).div(100).round(2, Big[rm]).toNumber()
  return convertUsdToMicroalgos(usd, algoUsd)
}

export const roundMicroalgos = (amount = 0, decimals = 0, rm: RoundingMode = 'roundHalfUp') => {
  const factor = new Big(10).pow(6)
  return new Big(amount).div(factor).round(decimals, Big[rm]).times(factor).toNumber()
}

export const formatNumber = (number = 0, options?: Intl.NumberFormatOptions | undefined) => {
  return new Intl.NumberFormat(undefined, options).format(number)
}

export const formatPrice = (
  price = 0,
  isAlgos = false,
  options?: Intl.NumberFormatOptions | undefined
) => {
  const algos = isAlgos ? price : convertMicroalgosToAlgos(price)
  return new Intl.NumberFormat(undefined, options).format(algos)
}

export const formatWithPrecision = (num = 0, precision = 0) => {
  let scaledNum = num
  let suffix = ''
  if (num >= 1e12) {
    suffix = 'T'
    scaledNum = num / 1e12
  } else if (num >= 1e9) {
    suffix = 'B'
    scaledNum = num / 1e9
  } else if (num >= 1e6) {
    suffix = 'M'
    scaledNum = num / 1e6
  } else if (num >= 1e3) {
    suffix = 'K'
    scaledNum = num / 1e3
  }
  return scaledNum.toFixed(precision) + suffix
}

export const isAssetNft = (totalCreated: number, assetDecimals: number) => {
  return totalCreated / Math.pow(10, assetDecimals) <= 10000
}

export const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(' ')
}

export const clsxMerge = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}

interface TruncateAddressOptions {
  startChars?: number
  endChars?: number
  array?: boolean
}

export const truncateAddress = (addr: string | undefined, options: TruncateAddressOptions = {}) => {
  if (!addr) {
    return ''
  }

  const { startChars = 6, endChars = 4, array = false } = options

  const start = addr.slice(0, startChars)
  const end = addr.slice(addr.length - endChars)

  if (array) {
    return [start, end]
  }

  return `${start}...${end}`
}

/**
 * Returns true/false if the given name is valid
 * foo.algo [true]
 * foo [false]
 * foo.bar [false]
 * foo.algo.bar [false]
 * 🚀.algo [true]
 * 123.algo [true]
 * foo🚀.algo [false]
 * FOO.algo [false]
 */
// export const isValidName = (name: string): boolean => {
//   return /^[a-z0-9]+\.algo$|^[\u{203C}\u{2049}\u{20E3}\u{2122}\u{2139}\u{2194}-\u{2199}\u{21A9}-\u{21AA}\u{231A}-\u{231B}\u{23E9}-\u{23EC}\u{23F0}\u{23F3}\u{24C2}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2600}-\u{2601}\u{260E}\u{2611}\u{2614}-\u{2615}\u{261D}\u{263A}\u{2648}-\u{2653}\u{2660}\u{2663}\u{2665}-\u{2666}\u{2668}\u{267B}\u{267F}\u{2693}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26CE}\u{26D4}\u{26EA}\u{26F2}-\u{26F3}\u{26F5}\u{26FA}\u{26FD}\u{2702}\u{2705}\u{2708}-\u{270C}\u{270F}\u{2712}\u{2714}\u{2716}\u{2728}\u{2733}-\u{2734}\u{2744}\u{2747}\u{274C}\u{274E}\u{2753}-\u{2755}\u{2757}\u{2764}\u{2795}-\u{2797}\u{27A1}\u{27B0}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F1E7}-\u{1F1EC}\u{1F1EE}-\u{1F1F0}\u{1F1F3}\u{1F1F5}\u{1F1F7}-\u{1F1FA}\u{1F201}-\u{1F202}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F23A}\u{1F250}-\u{1F251}\u{1F300}-\u{1F320}\u{1F330}-\u{1F335}\u{1F337}-\u{1F37C}\u{1F380}-\u{1F393}\u{1F3A0}-\u{1F3C4}\u{1F3C6}-\u{1F3CA}\u{1F3E0}-\u{1F3F0}\u{1F400}-\u{1F43E}\u{1F440}\u{1F442}-\u{1F4F7}\u{1F4F9}-\u{1F4FC}\u{1F500}-\u{1F507}\u{1F509}-\u{1F53D}\u{1F550}-\u{1F567}\u{1F5FB}-\u{1F640}\u{1F645}-\u{1F64F}\u{1F680}-\u{1F68A}]+\.algo$/gmu.test(
//     name
//   )
// }

/**
 * @description Checks if name is a valid NFD root/segment
 * @param name string to validate
 * @param suffixOptional if true, '.algo' suffix is optional (default: false)
 * @returns true if valid
 */
export const isValidName = (name: string, suffixOptional = false): boolean => {
  if (suffixOptional) {
    return /^([a-z0-9]{1,27}\.){0,1}(?<basename>[a-z0-9]{1,27})(\.algo)?$/g.test(name)
  }
  return /^([a-z0-9]{1,27}\.){0,1}(?<basename>[a-z0-9]{1,27})\.algo$/g.test(name)
}

/**
 * @description Checks if name is a valid NFD root
 * @param name string to validate
 * @param suffixOptional if true, '.algo' suffix is optional (default: false)
 * @returns true if valid
 */
export const isValidRoot = (name: string, suffixOptional = false): boolean => {
  if (suffixOptional) {
    return /^[a-z0-9]{1,27}(\.algo)?$/g.test(name)
  }
  return /^[a-z0-9]{1,27}\.algo$/g.test(name)
}

/**
 * @description Checks if name is a valid NFD segment
 * @param name string to validate
 * @param suffixOptional if true, '.algo' suffix is optional (default: false)
 * @returns true if valid
 */
export const isValidSegment = (name: string, suffixOptional = false): boolean => {
  if (suffixOptional) {
    return /^[a-z0-9]{1,27}\.(?<basename>[a-z0-9]{1,27})(\.algo)?$/g.test(name)
  }
  return /^[a-z0-9]{1,27}\.(?<basename>[a-z0-9]{1,27})\.algo?$/g.test(name)
}

/**
 * @description Trims the '.algo' suffix from the input string, if it exists
 * @param str string to trim
 * @returns input string with suffix removed
 */
export const trimExtension = (str: string): string => {
  return str.replace(/\.algo$/gi, '')
}

/**
 * @description Trims the segment prefix from the input string, if it exists
 * @param str string to trim
 * @returns input string with prefix removed
 */
export const trimSegment = (str: string): string => {
  if (!isValidName(str)) {
    return str
  }
  return str.match(/^[a-z0-9]{1,27}\.algo$/gi) ? str : str.split('.')[1] + '.algo'
}

/**
 * @description Checks if input string is a valid Algorand address (regex only)
 * @param str string to validate
 * @returns true if valid address
 */
export const isValidAlgoAddress = (str: string): boolean => {
  return /[A-Z2-7]{58}/.test(str)
}

/**
 * @param ms Delay in milliseconds
 * @description Delays the execution of the next line by the given amount of milliseconds
 * @example `async () => { await delay(5000); console.log('5 seconds'); }`
 */
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

/**
 * Format usernames for display, helpful for preventing double @@ symbols
 * @param username Username to check for '@' symbol
 * @param stripSymbol Show @ or not
 */
export const formatUsername = (username = '', stripSymbol = false): string => {
  if (username.startsWith('@')) {
    return stripSymbol ? username.slice(1) : username
  }

  return stripSymbol ? username : `@${username}`
}

export const getNfdUrl = (name: string) => {
  return `${CLIENT_BASE_URL || 'https://app.testnet.nf.domains'}/name/${name}`
}

export const getTwitterShareLink = (name: string, forSale = false) => {
  const url = getNfdUrl(name)
  const text = forSale
    ? encodeURIComponent(`Check it out, ${name} is for sale! ${url}\n\n#NFD`)
    : encodeURIComponent(`Check out ${name}'s NFD profile: ${url}\n\n#NFD`)

  return `https://x.com/intent/tweet?text=${text}&via=nfdomains&original_referer=${url}`
}

export const getBlueskyShareLink = (name: string, forSale = false) => {
  const url = getNfdUrl(name)
  const text = forSale
    ? encodeURIComponent(`Check it out, ${name} is for sale! ${url}\n\n#NFD`)
    : encodeURIComponent(`Check out ${name}'s NFD profile: ${url}\n\n#NFD`)

  return `https://bsky.app/intent/compose?text=${text}`
}

/**
 * @description Used to group an array of strings into chunks of a given size. This can be used when requesting a batch of names from the API, where the limit is 20 addresses per request.
 */
export const chunkArray = (array: string[], perChunk = 20) => {
  return array.reduce<string[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

/**
 * @description Retries an async function recursively n times.
 */
export async function retry(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any) => Promise<any>,
  retries = 3,
  err: unknown = null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  if (retries === 0) {
    return Promise.reject(err)
  }

  return callback().catch((err: unknown) => {
    return retry(callback, retries - 1, err)
  })
}

export const stopPropagation = (
  e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
) => {
  e.stopPropagation()
}

export const isBreakpoint = (bp: Breakpoint) => {
  return window.matchMedia(`(min-width: ${breakpoints[bp]}px)`).matches
}

/**
 * This function will return the current breakpoint based on the viewport width.
 * Used for performing "media queries" in JS.
 *
 * @param filterBy only return breakpoints in this array
 * @returns current breakpoint
 */
export const getBreakpoint = (filterBy?: Array<Breakpoint>): Breakpoint => {
  const sortedBreakpoints = Object.entries(breakpoints)
    .filter(([key]) => (filterBy ? filterBy.includes(key as Breakpoint) : true))
    .sort((a, b) => b[1] - a[1])
    .map((entry) => entry[0]) as Array<Breakpoint>

  for (let i = 0; i < sortedBreakpoints.length; i++) {
    const bp = sortedBreakpoints[i]
    if (isBreakpoint(bp)) {
      return bp
    }
  }

  return 'default'
}

export const getViewportHeight = () => {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
}

export const getViewportWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}
